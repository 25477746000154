<template>
  <div>
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="searchForm"
        label-width="85px"
      >
        <el-form-item label="商品单位">
          <el-input
            v-model="tableFrom.name"
            placeholder="请输入商品单位"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="handleSearch()"
            >搜索</el-button
          >
          <el-button size="small" @click="handleReset()">重置</el-button>

          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="onAdd"
            >添加单位</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-card class="box-card">
      <div class="mb20 acea-row"></div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        row-key="id"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
       <el-table-column
          type="index"
          :index="getTabelIndex"
          width="70" 
          label="序号"
        />
        <el-table-column label="单位名称" align="center" prop="name" min-width="100">
        </el-table-column>
        <el-table-column prop="sort" align="center" label="排序值" min-width="50" />
        <el-table-column label="操作" align="center" min-width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" icon="el-icon-edit" @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.page"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="300"
      :before-close="handleClose"
    >
      <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
        @submit="formSubmit"
      >
        <template slot="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
        </template>
        <template slot="img">
          <FormImgUpload
            :url="form.img"
            @upload="uploadImg"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  goodsUnitAdd, 
  goodsUnitDel,
  getGoodsUnitList,
  goodsUnitUp,
} from "@/api/goods/goods";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import {getIndex} from "@/utils/other";
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        name: "",
      },
      title: "新增单位",
      dialogVisible: false,
      form: {
        name: "",
        sort:1
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "名称",
            prop: "name",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            span:20,
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "排序",
            prop: "sort",
            controlsPosition: "",
            type: "number",
            span:20,
            min:1,
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index,this.tableFrom.pageNum,this.tableFrom.pageSize)
    },
    handleSearch() {
      this.tableFrom.page = 1;
      this.getList();
    },
    /* 重置查询 */
    handleReset() {
      console.log("重置查询");
      this.tableFrom.name = "";
      this.tableFrom.page = 1;
      this.$refs.searchForm.resetFields();
      this.getList();
    },
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.image = res.image;
      // this.$nextTick(() => {
      //   this.form.image = res.path;
      // });
    },
    formSubmit() {},

    submit(form) {
      console.log(this.form, "this.formthis.form");
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;

          if (this.form.id) {
            goodsUnitUp({
              ...that.form,
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                that.getList();
                that.handleClose();
                // done();
              } else {
                that.$message.error(res.msg);
                // done();
              }
            });
          } else {
            goodsUnitAdd({
              ...that.form,
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                that.$message.success(res.msg);
                that.getList();
                that.handleClose();
                // done();
              } else {
                that.$message.error(res.msg);
                // done();
              }
            });
          }
          this.form.type = 1;
          // done()
        }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.image = [];
      this.form.img = [];
      this.form.isShow = 0;
      this.dialogVisible = false;
    },
    // 列表
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    // 列表
    //图片上传
    uploadImg(e) {
      if (Array.isArray(e)) {
        this.form.img = [...e];
      } else {
        this.form.img = [...this.form.img, e];
      }
    },
    getList() {
      this.listLoading = true;
      getGoodsUnitList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },

    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    // 添加
    onAdd() {
      this.title = '新增品牌'
      this.form.id = -null;
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.title = '编辑品牌'
      this.form = JSON.parse(JSON.stringify(row));
      this.form.image = [this.form.logo];
      if(this.form.img){
        this.form.img = [this.form.img];
      }
      this.form.parentId = [this.form.parentId];
      console.log("1231", this.form);
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(id) {
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            goodsUnitDel(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.getList();
                  that.$message.success(res.msg);
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    }
  },
};
</script>

<style scoped lang="scss"></style>
